
import { prop, Vue } from "vue-class-component";
import { ChipsStyles } from "./modules";

class Props {
  label = prop<string>({
    default: ""
  });
  title = prop<string>({
    default: ""
  });
  square = prop<boolean>({
    default: false,
    type: Boolean
  });
  boldText = prop<boolean>({
    default: true,
    type: Boolean
  });
}
export default class ChipsV2 extends Vue.with(Props) {
  get chipStyles() {
    return ChipsStyles({
      status: this.label,
      title: this.title,
      square: this.square,
      boldText: this.boldText
    });
  }
}
